<template>
    <footer class="main-footer">
        <p class="icph-copyright">&copy; {{ year }} ICPH</p>
        <nav class="icph-menu">
            <!-- <a class="icph-menu-item" @click="show_terms_popup = true">Terms of Use</a> -->
            <router-link :to="{ name: 'Privacy Policy' }" class="icph-menu-item" @click="show_privacy_popup = true">privacy policy</router-link>
        </nav>
        <PrivacyPopup v-model="show_privacy_popup" />
        <TermsPopup v-model="show_terms_popup" />
    </footer>
</template>

<script>
    import PrivacyPopup from  '@/popups/Privacy';
    import TermsPopup from  '@/popups/Terms';

    export default
    {
        name: 'Footer',
        components:
        {
            PrivacyPopup,
            TermsPopup,
        },
        data()
        {
            return {
                year: new Date().getFullYear(),
                show_privacy_popup: false,
                show_terms_popup: false,
            };
        },
        mounted()
        {
            if (this.$route.name === 'Privacy Policy')
            {
                this.show_privacy_popup = true;
            }
        },
    }
</script>
