<template>
    <div class="icph-page-welcome">
        <form @submit.prevent="update_profile">
            <panel>
                <h2>Welcome <span>{{ model.name || user.name || '' }}</span></h2>
                <p class="icph-lineunder">As this is your first time logging in, please make sure we have the right information for you and confirm you agree with the privacy policy.</p>
                <inner-panel
                    title="USER PROFILE"
                    :icon="require('@/assets/images/icons/cog.svg')"
                    >
                    <profile-picture></profile-picture>
                    <base-input
                        label="NAME &amp; SURNAME"
                        placeholder="Enter your name"
                        v-model="model.name"
                        :error="get_errors('name')"
                        >
                    </base-input>
                    <base-input
                        label="E-MAIL ADDRESS"
                        placeholder="Enter your e-mail address"
                        v-model="model.email"
                        :error="get_errors('email')"
                        >
                    </base-input>
                    <dropdown
                        label="THEMATIC SCOPE"
                        placeholder="Select your scope"
                        v-model="model.thematic_scope"
                        mode="tags"
                        :closeOnSelect="false"
                        :round="true"
                        :options="topics"
                        >
                    </dropdown>
                    <base-input
                        label="ORGANISATION"
                        placeholder="Select your organisation"
                        v-model="model.organization"
                        >
                    </base-input>
                    <dropdown
                        label="BASED COUNTRY"
                        placeholder="Select your country"
                        v-model="model.country_id"
                        :options="countries"
                        :round="true"
                        >
                    </dropdown>
                </inner-panel>
                <Button
                    :round="true"
                    color="primary"
                    type="submit"
                    >
                    Save
                </Button>
                <label>I <a @click="show_privacy_popup = true">agree</a> with the privacy policy and GDPR</label>

                <SwitchButton
                    v-model:checked="model.agree_privacy"
                    type="info"
                    on-text="ON"
                    off-text="OFF"
                    :error="get_errors('agree_privacy', 'You must agree to the privacy policy before proceeding')"
                    >
                </SwitchButton>
            </panel>
        </form>
        <PrivacyPopup v-model="show_privacy_popup" />
    </div>
</template>

<script>
    import Panel from '@/components/Panels/Panel';
    import InnerPanel from '@/components/Panels/InnerPanel';
    import BaseInput from '@/components/Inputs/BaseInput';
    import Button from '@/components/Buttons/Button';
    import SwitchButton from '@/components/Buttons/SwitchButton';
    import ProfilePicture from '@/components/ProfilePicture';
    import Dropdown from '@/components/Inputs/Dropdown';
    import PrivacyPopup from  '@/popups/Privacy';

    import { CountryService, TopicService, UserService } from '@/services';

    import useVuelidate from '@vuelidate/core';
    import { required, email, sameAs } from '@vuelidate/validators';

    export default
    {
        name: 'Welcome',
        components:
        {
            Panel,
            InnerPanel,
            BaseInput,
            Button,
            SwitchButton,
            ProfilePicture,
            Dropdown,
            PrivacyPopup,
        },
        setup()
        {
            return { v$: useVuelidate() };
        },
        data()
        {
            return {
                show_privacy_popup: false,
                countries: [],
                topics: [],
                country: {},
                model:
                {
                    name: '',
                    email: '',
                    thematic_scope: [],
                    organization: '',
                    country_id: 0,
                    countries: [],
                    agree_privacy: false,
                },
            };
        },
        validations()
        {
            return {
                model:
                {
                    name: { required },
                    email: { required, email },
                    agree_privacy: { required, sameAs: sameAs(true) },
                },
            };
        },
        computed:
        {
            user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async created()
        {
            this.model = {...this.model, ...this.user};

            this.countries = (await CountryService.all()).data;
            this.topics = (await TopicService.all()).data;

            this.model.country_id = String(this.model.country_id);

            this.countries.forEach((item) =>
            {
                item.value = item.id;
                item.label = item.name;
            });

            this.topics.forEach((item) => {
                item.value = item.name;
                item.label = item.name;
            })

            // this.country = this.countries.filter((value) => value.id === this.model.country_id).pop();
        },
        methods:
        {
            async update_profile()
            {
                const is_valid = await this.v$.$validate();

                if (!is_valid)
                {
                    return ;
                }

                if (this.country.id)
                {
                    this.model.country_id = this.country.id;
                }

                UserService.update(this.user.id, this.model).then(() =>
                {
                    this.$toast.success('User profile was successfully updated!');
                    this.$router.push('/');
                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }
                });
            },

            get_errors(field, message)
            {
                const $err = this.v$.model[field];

                if ($err.$error)
                {
                    return message || $err.$errors.map((err) => err.$message).join(' - ');
                }

                return false;
            }
        },
    }
</script>
